.pageContainer[id='zinepage'] {
  transform: translateY(5%);
  overflow: hidden;
}

.carousel-root {
  max-width: 80%;
  max-height: 100vh;
}

.react-responsive-carousel .carousel .slide {
  max-width: 100%;
  height: auto;
}

.carousel-slider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .slider-wrapper {
  width: 70vh;
  height: 100%;
}

.carousel .slide img {
  width: 100%;
  height: auto;
  padding: 2vh;
  border: 1vh solid black;
}

.carousel-arrow {
  cursor: pointer;
  font-size: 8vh;
}

.carousel-arrow-left {
  padding-right: 2vh;
}

.carousel-arrow-right {
  padding-left: 2vh;
}

.carousel-arrow:hover {
  color: green;
}

.arrow-disabled {
  color: darkgrey;
}

.arrow-disabled:hover {
  cursor: not-allowed;
  color: darkgrey;
}

@media screen and (max-width: 1400px) and (orientation: portrait) {
  .pageContainer[id='zinepage'] {
    transform: translateY(25%);
  }
  .carousel-root {
    max-width: 100%;
    max-height: 100vh;
  }
  .carousel .slider-wrapper {
    width: 90vw;
    height: 100%;
  }
  .carousel-slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .carousel-arrow-left {
    order: 1;
    font-size: 8vh;
    transform: translate(-100%, 0%);
  }
  .carousel-arrow-right {
    order: 1;
    font-size: 8vh;
    transform: translate(100%, -100%);
  }
}

@media screen and (min-width: 700px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1400px) and (orientation: portrait) {
  .pageContainer[id='zinepage'] {
    transform: translateY(15%);
  }
  .carousel .slider-wrapper {
    width: 80vw;
    height: 100%;
  }
}

@media screen and (max-width: 700px) and (max-height: 800px) and (orientation: portrait) {
  .pageContainer[id='zinepage'] {
    transform: translateY(15%);
  }
  .carousel .slider-wrapper {
    width: 80vw;
    height: 100%;
  }
}
