@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,500;1,400;1,500&display=swap');

body {
  background-image: url('./assets/bg-01.png');
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

body.whitebg {
  background-image: none;
  background-color: white;
}

body.aboutbg {
  /* background-image: url('./assets/czM6Ly9tZWRpYS1wcml2YXRlLmNhbnZhLmNvbS95c19fSS9NQUYycmx5c19fSS8xL3AuanBn.jpeg'); */
  background-color: grey;
  background-image: none;
}

* {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0;
}

@font-face {
  font-family: 'Celandine';
  src: url('./fonts/ED-Celandine-Regular.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 6vh;
  font-family: 'Celandine', sans-serif;
}

h3 {
  font-size: 4vh;
  font-family: 'Celandine', sans-serif;
}

h4,
h5,
h6 {
  font-family: 'JetBrains Mono', monospace;
}

a {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 1400px) and (orientation: portrait) {
  body {
    background-image: url('./assets/bg-01.png');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  h1 {
    font-size: 3vw;
  }
  h2 {
    font-size: 2.5vw;
  }
  h3 {
    font-size: 2vw;
  }
}

@media screen and (max-width: 1200px) and (orientation: portrait) {
  h1 {
    font-size: 3vh;
  }
  h2 {
    font-size: 3.5vh;
  }
  h3 {
    font-size: 3vh;
  }
}

@media screen and (orientation: landscape) {
  body.overflowRestrict {
    overflow: hidden;
  }
}
