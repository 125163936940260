.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  position: absolute;
  top: 5%;
  left: 5%;
}

.overflowContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 10vh;
  width: 100vw;
  height: 60vh;
  overflow: scroll;
}

.titleBox {
  background-color: white;
  border: 1vh solid black;
  padding: 5vh 20vh;
  max-width: 50vh;
  text-align: center;
}

.sectionBox {
  background-color: white;
  border: 1vh solid black;
  padding: 3vh;
  max-width: 50vh;
  text-align: center;
}

.sectionBox:hover {
  cursor: pointer;
  border-color: #149954;
}

.chartLine.vertical {
  width: 1vh;
  height: 15vh;
  background-color: black;
}

.chartLine.horizontal {
  width: 15vh;
  height: 1vh;
  background-color: black;
}

@media screen and (max-width: 1400px) and (orientation: portrait) {
  .titleBox {
    border: 3px solid black;
  }
  .sectionBox {
    background-color: white;
    border: 1vh solid black;
    padding: 3vh;
    max-width: 50vh;
    text-align: center;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  .titleBox {
    border: 3px solid black;
  }
  .sectionBox {
    background-color: white;
    border: 0.75vw solid black;
    padding: 3vh;
    max-width: 50vh;
    text-align: center;
  }
}

