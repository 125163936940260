.landingChartContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.horizontalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}

.sectionBox[id='landing1'] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vh;
  max-width: 80vh;
  height: 30vh;
  max-height: 30vh;
}

.sectionBox[id='landing1']:hover {
  cursor: auto;
  border-color: black;
}

.sectionBox[id='landing2'] {
  padding: 3vh 7vh;
}

.sectionBox[id='landing2']:hover {
  border-color: #e4312b;
}

.sectionBox[id='landing3'] {
  padding: 3vh 7vh;
}

.typeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.type1 {
  text-wrap: nowrap;
}

.chartLine.horizontal[id='horizontal1'] {
  width: 60vh;
  height: 1vh;
  background-color: black;
}

@media screen and (max-width: 1200px) and (orientation: portrait) {
  .typeContainer h1 {
    font-size: 4.5vh;
  }
  .type1 {
    text-wrap: wrap;
  }
  .sectionBox[id='landing1'] {
    max-width: 70vw;
    max-height: 20vh;
  }
  .sectionBox[id='landing2'] {
    padding: 2vh 4vh;
  }

  .sectionBox[id='landing3'] {
    padding: 2vh 4vh;
  }
  .chartLine.horizontal[id='horizontal1'] {
    width: 25vw;
    height: 1vh;
    background-color: black;
  }
  .chartLine.vertical[id='vertical1'] {
    width: 1vh;
    height: 20vh;
    background-color: black;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .landingChartContainer {
    -webkit-transform: translate(-50%, -45%);
    transform: translate(-50%, -45%);
  }
}
