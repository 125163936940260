.pageContainer[id='aboutpg'] {
  overflow: hidden;
}

.aboutSlideContainer h1 {
  font-size: 12vh;
}

.pageContainer[id='aboutpg'] .carousel-root {
  width: 60vw;
  max-width: 80%;
  max-height: 100%;
  transform: translateY(20%);
  overflow: hidden;
}

.pageContainer[id='aboutpg'] .carousel .slider-wrapper {
  width: 70vw;
  overflow: hidden;
}

.aboutSlideContainer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: left;
}

.aboutSlideLink a {
  text-decoration: underline;
  font-size: 2.5vh;
}

.aboutSlideLink {
  max-width: 50vw;
  word-wrap: break-word;
}

.aboutSlideLink a:hover {
  color: black;
}

.aboutSlideContent {
  background-color: grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  row-gap: 5vh;
  padding-left: 2vh;
}

.aboutSlideContent[id='slide1'] {
  padding: 5vh;
  row-gap: 10vh;
}

.aboutSlideContent[id='slide4'] {
  margin-top: 10vh;
}

.aboutSlideContent[id='slide5'] {
  row-gap: 10vh;
}

.aboutSlideTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutSlideTitle[id='slide4'] h1 {
  font-size: 8vh;
}

.aboutSlideInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 50vw;
  row-gap: 5vh;
  padding-left: 2vh;
}

#slide1 .aboutSlideInfo {
  padding-left: 0vh;
}

.aboutSlideInfo h4 {
  font-size: 3vh;
}

.aboutSlideDividerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.aboutSlideDividerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutSlideDividerLine {
  width: 50vw;
  height: 0.5vh;
  background-color: black;
}

.aboutSlideDividerPoint {
  width: 0;
  height: 0;
  border-top: 1vh solid transparent;
  border-bottom: 1vh solid transparent;
  border-left: 2vh solid black;
}

.aboutSlideFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1vh;
}

.aboutSlideFootnote {
  max-width: 60vh;
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  .pageContainer[id='aboutpg'] .carousel-root {
    transform: translateY(10%);
  }
  .aboutSlideTitle h1 {
    font-size: 6vw;
  }
  .aboutSlideFootnote {
    font-size: 1vw;
    max-width: 40vw;
  }
  .aboutSlideDividerPoint {
    border-top: 1vh solid transparent;
    border-bottom: 1vh solid transparent;
    border-left: 2vh solid black;
  }
}

@media screen and (max-width: 1400px) and (orientation: portrait) {
  .pageContainer[id='aboutpg'] .carousel-root {
    width: 100vw;
    max-width: 100%;
    height: 90vh;
    max-height: 90vh;
    transform: translateY(5%);
  }
  .pageContainer[id='aboutpg'] .carousel .slider-wrapper {
    width: 100vw;
    max-height: 70vh;
  }
  .aboutSlideContent {
    margin-top: 15vh;
    padding-left: 0vh;
  }
  .aboutSlideContent[id='slide1'] {
    margin-top: 15vh;
    row-gap: 5vh;
  }
  .aboutSlideContent[id='slide3'] {
    row-gap: 3vh;
  }
  .aboutSlideInfo[id='slide3'] {
    row-gap: 2vh;
  }
  .aboutSlideContent[id='slide4'] {
    margin-top: 15vh;
  }
  .aboutSlideContent[id='slide5'] {
    margin-top: 15vh;
  }
  .aboutSlideContent[id='slide6'] {
    margin-top: 15vh;
    row-gap: 2vh;
  }
  .aboutSlideTitle h1 {
    font-size: 6vh;
  }
  .aboutSlideInfo {
    max-width: 80vw;
    row-gap: 3vh;
  }
  .aboutSlideInfo h4 {
    font-size: 3vw;
  }
  .aboutSlideDividerLine {
    width: 80vw;
  }
  .aboutSlideLink {
    max-width: 70vw;
  }
  .aboutSlideLink a {
    font-size: 1.25vh;
  }
  .aboutSlideTitle[id='slide4'] h1 {
    margin-top: 0vh;
    font-size: 4vh;
    max-width: 80vw;
  }
  .aboutSlideContent[id='slide5'] {
    row-gap: 5vh;
  }
  .aboutSlideFootnote {
    max-width: 70vw;
    font-size: 1.25vh;
  }
}

@media screen and (max-width: 1400px) and (min-height: 1000px) and (max-height: 1400px) and (orientation: portrait) {
  .pageContainer[id='aboutpg'] .carousel-root {
    width: 100vw;
    max-width: 100%;
    height: 90vh;
    max-height: 90vh;
    transform: translateY(5%);
  }
  .pageContainer[id='aboutpg'] .carousel .slider-wrapper {
    width: 100vw;
    height: 80vh;
    max-height: 80vh;
  }
}
