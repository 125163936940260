.pageContainer[id='formpage'] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 9vh;
  margin-top: 5vh;
}

.titleBox[id='formTitle'] {
  width: 50vh;
  padding: 4vh 5vh 5vh 5vh;
  height: 10vh;
  max-width: 50vh;
  max-height: 5vh;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  max-width: 100vw;
  max-height: 70vh;
  height: 100%;
  overflow: scroll;
}

.warTaxForm {
  width: 80vh;
  height: 200vh;
  background-color: white;
  border: 1vh solid black;
  padding: 5vh 0vh;
}

@media screen and (max-width: 1400px) and (orientation: portrait) {
  .pageContainer[id='formpage'] {
    transform: translateY(10%);
    row-gap: 4vh;
  }
  .titleBox[id='formTitle'] {
    width: 50vw;
    padding: 3vh 2vh 1vh 2vh;
    height: 5vh;
    max-width: 50vw;
    max-height: 5vh;
    border: 1vh solid black;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: auto;
    overflow: auto;
  }
  .warTaxForm {
    width: 90%;
    max-width: 80vh;
    height: 45vh;
    background-color: white;
    border: 1vh solid black;
  }
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
  .pageContainer[id='formpage'] {
    row-gap: 4vh;
    transform: translateY(3%);
  }
  .titleBox[id='formTitle'] {
    width: 50vh;
    padding: 4vh 2vh 6vh 2vh;
    height: 5vh;
    max-width: 50vh;
    max-height: 10vh;
    border: 0.75vw solid black;
  }
  .warTaxForm {
    width: 80vw;
    height: 200vh;
    background-color: white;
    border: 0.75vw solid black;
    padding: 5vh 0vh;
  }
}
