.pageContainer[id='main'] {
  -webkit-transform: translate(-5%, 30%);
  transform: translate(-5%, 30%);
}

/* ADD WHEN #PLEDGED PAGE IS BACK */
/* .pageContainer[id='main'] {
  -webkit-transform: translate(-5%, 10%);
  transform: translate(-5%, 10%);
} */

@media screen and (max-width: 1400px) and (orientation: portrait) {
  .pageContainer[id='main'] {
    transform: translate(0, 5%);
    padding-bottom: 5vh;
  }
}

@media screen and (max-width: 1400px) and (orientation: landscape) {
  .pageContainer[id='main'] {
    -webkit-transform: translate(-5%, 40%);
    transform: translate(-5%, 40%);
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .pageContainer[id='main'] {
    -webkit-transform: translate(-5%, 25%);
    transform: translate(-5%, 25%);
  }
}

@media screen and (min-height: 500px) and (max-height: 800px) and (max-width: 800px) and (orientation: landscape) {
  .pageContainer[id='main'] {
    -webkit-transform: translate(-5%, 40%);
    transform: translate(-5%, 40%);
  }
}