.returnButton.invisible {
  display: none;
}

.returnButton.visible {
  position: fixed;
  top: 5%;
  left: 2%;
  z-index: 9999;
}

@media screen and (max-width: 1400px) {
  .returnButton.visible {
    left: 5%;
  }
}
