.pageContainer[id='pledgedPage'] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8vh;
  transform: translateY(5%);
}

.titleBox[id='pledgedTitle'] {
  padding: 3vh 10vh;
}

.titleBox[id='whywepledge'] {
  padding: 5vh 7vh;
}

.mapWrapper {
  width: 80vh;
  height: 40vh;
  background-color: white;
  border: 1vh solid black;
  padding: 5vh;
}

.imgBox {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pledgesBox {
  background-color: white;
  width: 100vh;
  border: 1vh solid black;
  padding: 5vh;
}

@media screen and (max-width: 1200px) and (orientation: portrait) {
  .pageContainer[id='pledgedPage'] {
    row-gap: 5vh;
    transform: translateY(25%);
  }
  .mapWrapper {
    width: 35vh;
    height: 40vh;
    padding: 3vh;
  }
  .titleBox[id='whywepledge'] {
    padding: 3vh 5vh;
  }
  .pledgesBox {
    width: 30vh;
  }
  .overflowContainer[id='pledgedPage'] {
    height: 50vh;
    row-gap: 5vh;
    padding-bottom: 5vh;
  }
}
